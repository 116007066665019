.lead_carry_input {
    border: 1px solid #d1d1d1;
    font-size: 24px;

}

.lead_carry_input:focus {
    border: 1px solid #2A2771;
    outline: none;
}

.lead_carry_input:hover {
    border: 1px solid #2A2771;
}