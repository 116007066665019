.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #eff3fb, #dde8f7);
  font-family: "Roboto", sans-serif;
}

.login-card {
  background: #ffffff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
  text-align: center;
  width: 100%;
  max-width: 400px;
  animation: fadeIn 0.5s ease-in-out;
}

.logo-container {
  margin-bottom: 2rem;
}

.logo {
  width: 240px;
}

.welcome-text {
  font-size: 1.75rem;
  font-weight: bold;
  color: #0f4593;
  margin-bottom: 0.75rem;
}

.sub-text {
  font-size: 1rem;
  color: #6b7280;
  margin-bottom: 2.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
